import Link from "next/link";
import { Box } from "@chakra-ui/layout";

export const InternalLink = ({ children, href, ...rest }) => {
  return (
    <Link legacyBehavior href={href}>
      <Box as="a" href={href} {...rest}>
        {children}
      </Box>
    </Link>
  );
};
