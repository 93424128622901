import { Box } from "@chakra-ui/layout";
import { COMPANY_NAME, GROUP_NAME } from "config/site";
import { EXTERNAL_LINKS, route } from "config/routes";
import { useAppContext } from "pages/_app";

export const FooterCopyright = () => {
  const { colorScheme } = useAppContext();

  return (
    <Box fontSize="xs" color="gray.500" pb={12}>
      © {new Date().getFullYear()} {COMPANY_NAME} part of{" "}
      <Box
        as="a"
        href={EXTERNAL_LINKS.NUTUR_GROUP.href}
        fontWeight={500}
        transition="color .25s"
        _hover={{
          color: `${colorScheme}.400`,
        }}
      >
        {`${GROUP_NAME}. `}
      </Box>
      All rights reserved.
    </Box>
  );
};
