import { Box } from "@chakra-ui/react";
import {
  FormFieldLabel,
  Input,
  Textarea,
  IInput,
  FormFieldErrorMessage,
  ITextarea,
} from "components";
import { Field } from "formik";
import React from "react";
import { RadioGroup } from "components";

export const FormField = <T,>({
  name,
  placeholder,
  label,
  type = "input",
  options,
  isRequired,
  rows,
}: {
  name: keyof T;
  placeholder?: string;
  label?: string;
  type?: "input" | "textarea" | "radio";
  rows?: number;
  options?: any;
  isRequired?: boolean;
}) => {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }) => {
          const isInvalid = form.errors[name] && form.touched[name];

          const { onChange, onBlur, value } = field;

          const inputProps: IInput | ITextarea = {
            id: name as string,
            name: name as string,
            isInvalid,
            placeholder,
            value,
            onBlur,
            onChange,
            size: "lg",
          };

          const radioProps: any = {
            id: name,
            name,
            isInvalid,
            value,
            onChange,
            size: "lg",
          };

          return (
            <>
              {label && <FormFieldLabel>{label}</FormFieldLabel>}
              {type === "input" && <Input {...inputProps} />}
              {type === "textarea" && <Textarea {...inputProps} rows={rows} />}
              {type === "radio" && (
                <RadioGroup {...radioProps} options={options} />
              )}
              {isInvalid && (
                <FormFieldErrorMessage>
                  {form.errors[name]}
                </FormFieldErrorMessage>
              )}
            </>
          );
        }}
      </Field>
    </Box>
  );
};
