import { Input as _Input } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

export interface ITextarea {
  id?: string;
  name: string;
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  value?: string;
  size: "sm" | "md" | "lg";
  rows: number;
  defaultValue?: string;
  onChange?: any;
  onBlur?: any;
}

interface ITextareaSize {
  fontSize: "sm" | "md" | "lg";
  p: number;
}

const TextareaStates = {
  valid: {
    bg: "",
  },
  error: {
    bg: "red.50",
    borderColor: "red.500",
  },
};

const InputSizes: { [key in "sm" | "md" | "lg"]: ITextareaSize } = {
  sm: {
    fontSize: "sm",
    p: 6,
  },
  md: {
    p: 3,
    fontSize: "md",
  },
  lg: {
    p: 4,
    fontSize: "md",
  },
};

export const Textarea = ({
  id,
  name,
  placeholder,
  isValid,
  isInvalid,
  value,
  size = "md",
  defaultValue,
  onChange,
  rows,
  onBlur,
}: ITextarea) => {
  const state = isInvalid
    ? TextareaStates["error"]
    : isValid
    ? TextareaStates["valid"]
    : {};

  return (
    <Box
      as="textarea"
      id={id}
      name={name}
      placeholder={placeholder}
      bg="white"
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      w="100%"
      resize="none"
      value={value}
      verticalAlign="middle"
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      rows={rows}
      _focus={{
        bg: "gray.100",
        outline: "none",
      }}
      {...state}
      {...InputSizes[size]}
    />
  );
};
