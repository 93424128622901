import { Box } from "@chakra-ui/react";
import { useNavigationContext } from "./Navigation";

export const NavigationMain = ({ children }) => {
  const { isScrolled } = useNavigationContext();

  return (
    <Box
      height={{ base: isScrolled ? 16 : 20, lg: 20 }}
      width="100%"
      borderBottom="1px solid"
      transition="all .25s"
      backgroundColor={isScrolled && "rgba(255,255,255,.85)"}
      backdropFilter={isScrolled && "blur(10px)"}
      borderColor={isScrolled ? "gray.150" : "transparent"}
      shadow={isScrolled && "sm"}
    >
      {children}
    </Box>
  );
};
