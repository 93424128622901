import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";

export const ContentfulTable = ({ data }: { data: any }) => {
  return (
    <SimpleGrid
      columns={data[0].length}
      my={5}
    >
      {data[0].map((tableColumn: [], i: number) => (
        <Box key={i} p={2} fontWeight={500} color="gray.700" fontSize="sm" lineHeight="none" borderBottom="1px solid" borderColor="gray.150">
          {tableColumn}
        </Box>
      ))}
      {data
        .slice(1)
        .flat()
        .map((tableRow: [], index: number) => {
          return (
            <Box
              key={index}
              borderBottom={"1px solid"}
              borderColor={"gray.150"}
              fontSize="sm"
              p={2}
            >
              {tableRow}
            </Box>
          );
        })}
    </SimpleGrid>
  );
};