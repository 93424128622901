export const ROUTES = {
  HOMEPAGE_INDEX: {
    path: "/",
  },
  INSTANT_VALUATION_TOOL_INDEX: {
    path: "/instant-valuation-tool",
  },
  AUTOCALLER_INDEX: {
    path: "/autocaller",
  },
  LEAD_RESPONDER_INDEX: {
    path: "/lead-responder",
  },
  FACEBOOK_ADVERTISING_INDEX: {
    path: "/facebook-advertising",
  },
  DASHBOARD_INDEX: {
    path: "/dashboard",
  },
  MORTAGES_INDEX: {
    path: "/mortgages",
  },
  CONTACT_INDEX: {
    path: "/contact",
  },
  PRICING_INDEX: {
    path: "/pricing",
  },
  PRIVACY_INDEX: {
    path: "/privacy",
  },
  CUSTOMERS_INDEX: {
    path: "/customers",
  },
  GUIDES_INDEX: {
    path: "/guides",
  },
  TERMS_INDEX: {
    path: "/terms",
  },
  INTEGRATIONS_INDEX: {
    path: "/integrations",
  },
  ABOUT_INDEX: {
    path: "/about",
  },
  DEMO_INDEX: {
    path: "/demo",
  },
  DEMO_WEBINAR_INDEX: {
    path: "/demo-webinar",
  },
  LEGAL_INDEX: {
    path: "/legal",
  },
  INTERNAL_GUIDES_INDEX: {
    path: "/internal-team-guides",
  },
  COMPLAINTS_INDEX: {
    path: "/legal/complaints",
  },
  BOOK_A_MEETING_INDEX: {
    path: "/book-meeting",
  },
  RELEASE_NOTES: {
    path: "/release-notes",
  },
};

export const route = (path: keyof typeof ROUTES, param?: string) => {
  const x = ROUTES[path].path + (param ? "/" + param : "");

  return x;
};

export const EXTERNAL_LINKS = {
  LOGIN: {
    href: "https://dashboard.lead.pro",
  },
  SIGNUP_UP: {
    href: "https://dashboard.lead.pro/signup",
  },
  NUTUR_GROUP: {
    href: "https://nurtur.group/",
  },
  AUTOCALLER_DISCOVERY: {
    href: "https://meetings.hubspot.com/samleadpro/leadpro-discovery-call",
  },
  MORTGAGES_SPLASH: {
    href: "https://dashboard.lead.pro/tools/mortgages",
  },
};
