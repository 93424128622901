import {
  Box,
  Heading,
  Image,
  OrderedList,
  Text,
  Link,
  UnorderedList,
  AspectRatio,
} from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { ContentfulTable } from "./ContentfulTable";
import React from "react";
import { ContentfulVideo } from "./ContentfulVideo";
import { Document as RichTextDocument } from "@contentful/rich-text-types";
import { ContentfulCodeBlock } from "./ContentfulCodeBlock";
import { EmbedTypes } from "enums/contentful";
import slugify from "slugify";

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }: any, children: any) => {
      return (
        <Link color="blue.500" href={data.uri} isExternal>
          {children}
        </Link>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return (
        <Text mt={4} fontSize="base">
          {children}
        </Text>
      );
    },
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <Heading
        id={slugify(node.content[0].value, {
          lower: true,
        })}
        as="h2"
        mt={4}
        fontSize={["xl", "2xl"]}
        fontWeight={500}
        mb={2}
        borderBottom="1px solid"
        color="primary.600"
        borderColor="gray.150"
        pb={4}
      >
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => {
      return (
        <Heading
          id={slugify(node.content[0].value, {
            lower: true,
          })}
          as="h3"
          fontSize={["lg", "xl"]}
          fontWeight={500}
          mb={4}
          borderBottom="1px solid"
          borderColor="gray.150"
          pb={4}
          color="primary.600"
        >
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <Heading as="h4" fontSize={["lg", "2xl"]} fontWeight={500} mb={4} mt={6}>
        {children}
      </Heading>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const file = node.data.target.fields.file;

      if (file.contentType.includes(EmbedTypes.image))
        return (
          <Box
            rounded="md"
            border="1px solid"
            borderColor="gray.150"
            p={6}
            bg="white"
            textAlign="center"
          >
            <Image
              src={node.data.target.fields.file.url}
              display="inline-block"
              width="100%"
            />
          </Box>
        );

      if (file.contentType.includes(EmbedTypes.video))
        return <ContentfulVideo node={node} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
      if (node.data.target.fields.codeBlock) {
        const blockData =
          node.data.target.fields.codeBlock.content[0].content[0].value;
        return <ContentfulCodeBlock data={blockData}></ContentfulCodeBlock>;
      }

      if (node.data.target.fields.table) {
        const tableData = node.data.target.fields.table.tableData;
        return <ContentfulTable data={tableData} />;
      }
    },

    [BLOCKS.QUOTE]: (node: any, children: any) => {
      return (
        <Box bg="gray.50" borderLeft="4px solid" borderColor="primary.600">
          <Box px={6} py={1} pb={5}>
            {children}
          </Box>
        </Box>
      );
    },
    // [BLOCKS.OL_LIST]: (node: any, children: any) => {
    //   return <OrderedList>{children}</OrderedList>;
    // },
    // [BLOCKS.UL_LIST]: (node: any, children: any) => {
    //   return <UnorderedList>{children}</UnorderedList>;
    // },
  },
};

export const ContentfulRichText = ({ document }: { document: any }) => {
  return <>{document ? documentToReactComponents(document, options) : <></>}</>;
};
