import { Box, Stack } from "@chakra-ui/react";
import { InternalLink } from "components";

export const FooterLinksColumn = ({
  title,
  children,
  href,
}: {
  title: string;
  children?: React.ReactNode;
  href?: string;
}) => {
  return (
    <Box mb={{ base: 12, md: 0 }}>
      <Box
        mb={[0, 4]}
        fontSize="md"
        fontWeight={500}
        color="gray.800"
        lineHeight="none"
      >
        {href ? (
          <InternalLink href={href}>
            <Box
              lineHeight="none"
              rounded="lg"
              _hover={{
                color: "primary.600",
              }}
            >
              {title}
            </Box>
          </InternalLink>
        ) : (
          title
        )}
      </Box>
      <Stack spacing={4}>{children}</Stack>
    </Box>
  );
};
