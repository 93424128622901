import { mobileBreakpoint } from "./Navigation";
import { Box } from "@chakra-ui/layout";

export const NavigationLinks = ({ children }) => {
  return (
    <Box
      position="absolute"
      left="50%"
      height="100%"
      display={{ base: "none", [mobileBreakpoint]: "flex" }}
      transform="auto"
      translateX={"-50%"}
    >
      {children}
    </Box>
  );
};
