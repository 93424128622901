import { Box } from "@chakra-ui/layout";
import React from "react";

const DEFAULT_NAV_SPACING = 100;

interface INavigationPageSpaceProps {
  navSpace?: number;
}

export const NavigationPageSpacer: React.FC<INavigationPageSpaceProps> = ({
  navSpace = DEFAULT_NAV_SPACING,
}) => {
  return <Box h={{ base: navSpace }} className="Navigation__Page-Spacer" />;
};
