import { Modal, ModalOverlay, ModalProps } from "@chakra-ui/react";
import React, { ReactNode, useCallback, useState } from "react";

interface IActionPromptContext {
  setModal: (modalContent: ReactNode) => void;
  unsetModal: () => void;
}

export const ActionPromptProviderContext = React.createContext<
  IActionPromptContext | undefined
>(undefined);

export const ActionPromptProvider = (props: any) => {
  const [modalContent, setModalContent] = useState<ReactNode | undefined>(
    undefined
  );

  const setModal = useCallback((modal: ReactNode) => {
    return setModalContent(modal);
  }, []);

  const unsetModal = useCallback(() => {
    return setModal(null);
  }, [setModalContent]);

  return (
    <>
      <ActionPromptProviderContext.Provider value={{ unsetModal, setModal }}>
        {props.children}
        {modalContent && (
          <ActionPrompt modalContent={modalContent} unsetModal={unsetModal} />
        )}
      </ActionPromptProviderContext.Provider>
    </>
  );
};

interface ActionPromptProps {
  modalContent: ReactNode;
  unsetModal: () => void;
  size?: ModalProps["size"];
}

const ActionPrompt: React.FC<ActionPromptProps> = ({
  modalContent,
  unsetModal,
  size = "xl",
}) => {
  return (
    <Modal isOpen={true} onClose={() => unsetModal()} size={size}>
      <ModalOverlay />
      {modalContent}
    </Modal>
  );
};
