import { Box } from "@chakra-ui/react";

export const NavigationHamburger = () => {
  return (
    <Box
      w={10}
      h={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      cursor="pointer"
    >
      <Box width={5}>
        <Box width="100%" h={0.5} bg="black" mb={1.5} />
        <Box width="100%" h={0.5} bg="black" />
      </Box>
    </Box>
  );
};
