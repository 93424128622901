import { Box } from "@chakra-ui/layout";

export const FormFieldLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      as="label"
      lineHeight="none"
      display="block"
      width="100%"
      mb={2}
      fontWeight={500}
      fontSize="14px"
    >
      {children}
    </Box>
  );
};
