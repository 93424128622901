import { Box } from "@chakra-ui/layout";
import { InternalLink } from "components/atoms/InternalLink";

export const FooterItem = ({ children }) => {
  return (
    <Box fontSize="md" color="gray.600">
      {children}
    </Box>
  );
};

export const FooterLink = ({ children, href }: any) => {
  return (
    <InternalLink href={href}>
      <Box
        fontSize="md"
        cursor="pointer"
        color="gray.600"
        fontWeight={400}
        _hover={{
          color: "gray.900",
        }}
      >
        {children}
      </Box>
    </InternalLink>
  );
};
