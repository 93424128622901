import { Box } from "@chakra-ui/react";
import { Navigation } from "components/organisms/Navigation/Navigation";
import { Footer } from "components/organisms/Footer";

export const LayoutDefault = ({ children }) => {
  return (
    <Box>
      <Navigation />
      {children}
      <Footer />
    </Box>
  );
};
