import { Box } from "@chakra-ui/react";
import { MAX_SITE_WIDTH } from "config/site";

export const Container = ({ children, ...rest }) => {
  return (
    <Box width="100%" maxW={MAX_SITE_WIDTH} mx="auto" px={{base: 4, lg: 4}} {...rest}>
      {children}
    </Box>
  );
};
