export const SITE_DESCRIPTION =
  "Built specifically to help Estate Agents generate, nurture and convert leads from a variety of sources.";

export const SITE_URL = "lead.pro";

export const SITE_NAME = "LeadPro";

export const DEFAULT_SEO = {
  title: SITE_NAME,
  description: SITE_DESCRIPTION,
  openGraph: {
    type: "website",
    description: SITE_DESCRIPTION,
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL}/images/social/default.png`,
        width: 1200,
        height: 630,
      },
    ],
    url: SITE_URL,
    site_name: SITE_NAME,
  },
  twitter: {
    handle: "",
    cardType: "summary_large_image",
  },
};
