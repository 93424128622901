import { Box } from "@chakra-ui/layout";

export const Number = ({ number, color = "primary" }) => {
  return (
    <Box
      w={9}
      h={9}
      display="flex"
      alignItems="center"
      justifyContent="center"
      rounded="full"
      bg={`${color}.600`}
      border="1px solid"
      borderColor={`${color}.700`}
      color="white"
      shadow="sm"
      fontSize="lg"
      fontWeight={600}
      pr={number === 1 && 0.5}
    >
      {number}
    </Box>
  );
};
