import { Box, Text, Heading } from "@chakra-ui/react";
import { HiCheck } from "react-icons/hi";

export const Checkpoint = ({ label, color = "primary" }) => {
  return (
    <Box display="flex" alignItems="flex-start" my={3}>
      <Box position="relative" mr={3}>
        <Box
          w={6}
          h={6}
          border="2px solid"
          rounded="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={`${color}.500`}
          fontSize="md"
        >
          <HiCheck />
        </Box>
      </Box>
      <Box opacity={0.85} fontWeight={500}>
        {label}
      </Box>
    </Box>
  );
};
