import { Box } from "@chakra-ui/react";
import { MAX_SITE_WIDTH } from "config/site";

export const NavigationContainer = ({ children }) => {
  return (
    <Box
      maxW={MAX_SITE_WIDTH}
      mx="auto"
      display="flex"
      pos="relative"
      alignItems="center"
      height="100%"
      px={4}
    >
      {children}
    </Box>
  );
};
