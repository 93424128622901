import { Input as _Input } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { useControllableState } from "@chakra-ui/hooks";

export interface IInput {
  id?: string;
  name: string;
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  value?: string;
  size: "sm" | "md" | "lg";
  defaultValue?: string;
  onChange?: any;
  onBlur?: any;
}

interface IInputSize {
  height: number;
  fontSize: "sm" | "md" | "lg";
  px: number;
}

const InputStates = {
  valid: {
    bg: "",
  },
  error: {
    bg: "red.50",
    borderColor: "red.500",
  },
};

const InputSizes: { [key in "sm" | "md" | "lg"]: IInputSize } = {
  sm: {
    height: 9,
    fontSize: "sm",
    px: 6,
  },
  md: {
    height: 10,
    px: 3,
    fontSize: "md",
  },
  lg: {
    height: 12,
    px: 4,
    fontSize: "md",
  },
};

export const Input = ({
  id,
  name,
  placeholder,
  isValid,
  isInvalid,
  value,
  size = "md",
  defaultValue,
  onChange,
  onBlur,
}: IInput) => {
  const state = isInvalid
    ? InputStates["error"]
    : isValid
    ? InputStates["valid"]
    : {};

  return (
    <Box
      as="input"
      id={id}
      name={name}
      placeholder={placeholder}
      bg="white"
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      w="100%"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      _focus={{
        borderColor: "primary.600",
        outline: "none",
      }}
      {...state}
      {...InputSizes[size]}
    />
  );
};
