export const MAX_SITE_WIDTH = 1280;

export const CS_PHONE = "+44 (0)20 8908 8908";

export const CALENDLY_LINK = "https://calendly.com/leadpro-customer-success";

export const CS_EMAIL = "contact@lead.pro";

export const COMPANY_NAME = "Property Technology Ltd";

export const GROUP_NAME = "nurtur.group";

export const COMPANY_NUMBER = "SC528448";

export const COMPANY_ADDRESS =
  "16 Charlotte Square, Edinburgh, Scotland, EH2 4DF";

export const GOOGLE_TAG_MANAGER_ID = "GTM-THCXHGZ4";
