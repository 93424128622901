import { Box, Stack, Divider, Portal } from "@chakra-ui/react";
import { HiX } from "react-icons/hi";
import { MotionBox } from "components";
import { Button, InternalLink } from "components";
import { useNavigationContext } from "./Navigation";
import { NavigationDropdownMenuProductLink } from "./NavigationDropdownMenuProductLink";
import { EXTERNAL_LINKS, route } from "config/routes";
import { useEffect } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useWindowSize } from "hooks/use-window-size";

import { mobileBreakpoint } from "./Navigation";
import { theme } from "theme";
import { isBrowser } from "@chakra-ui/utils";
import { useAppContext } from "pages/_app";

const NavigationMobileDrawerProduct = ({ children }) => {
  return (
    <Box display="flex" cursor="pointer" alignItems="center">
      <Box mr={3}>
        <Box w={8} h={8} bg="green.100" rounded="sm"></Box>
      </Box>
      <Box>
        <Box
          fontSize="sm"
          fontWeight="bold"
          whiteSpace="nowrap"
          lineHeight="none"
          mb={1}
        >
          {children}
        </Box>
        <Box fontSize="sm" lineHeight="none" opacity={0.75}>
          Description comes here.
        </Box>
      </Box>
    </Box>
  );
};

const NavigationDropdownMenuLink = ({ children }) => {
  return <Box>{children}</Box>;
};

export const NavigationMobileDrawer = () => {
  const { onClose, isOpen } = useNavigationContext();

  const {
    products: {
      IVT,
      Autocaller,
      LeadResponder,
      Dashboard,
      FacebookAdvertising,
    },
  } = useAppContext();

  const { width } = useWindowSize();

  const router = useRouter();

  const bp = theme.breakpoints[mobileBreakpoint];

  if (isBrowser) router.events.on("routeChangeStart", onClose);

  useEffect(() => {
    const { matches } = window.matchMedia(`screen and (min-width: ${bp})`);

    if (matches && isOpen) onClose();
  }, [width]);

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <MotionBox
            key="1"
            top={12}
            style={{
              position: "fixed",
              width: "100%",
              left: 0,
              backdropFilter: "blur(10px)",
              zIndex: 999,
            }}
            transition={{
              type: "tween",
            }}
            initial={{ opacity: 0, transform: "scale(0.99)" }}
            animate={{ opacity: 1, transform: "scale(1)" }}
            exit={{ opacity: 0, transform: "scale(0.99)" }}
          >
            <Box
              w="100%"
              backgroundColor="rgba(245,245,245,.95)"
              boxShadow="md"
            >
              <Box p={6} pb={0}>
                <Box
                  textTransform="uppercase"
                  letterSpacing="wide"
                  fontSize="sm"
                  fontWeight="medium"
                  color="gray.600"
                >
                  Products
                </Box>
              </Box>

              <InternalLink href={route("INSTANT_VALUATION_TOOL_INDEX")}>
                <NavigationDropdownMenuProductLink product={IVT}>
                  Instant Valuation Tool
                </NavigationDropdownMenuProductLink>
              </InternalLink>
              <InternalLink href={route("LEAD_RESPONDER_INDEX")}>
                <NavigationDropdownMenuProductLink product={LeadResponder}>
                  LeadResponder
                </NavigationDropdownMenuProductLink>
              </InternalLink>
              <InternalLink href={route("AUTOCALLER_INDEX")}>
                <NavigationDropdownMenuProductLink product={Autocaller}>
                  Autocaller
                </NavigationDropdownMenuProductLink>
              </InternalLink>
              <InternalLink href={route("FACEBOOK_ADVERTISING_INDEX")}>
                <NavigationDropdownMenuProductLink
                  product={FacebookAdvertising}
                >
                  FacebookAdvertising
                </NavigationDropdownMenuProductLink>
              </InternalLink>
              <InternalLink href={route("DASHBOARD_INDEX")}>
                <NavigationDropdownMenuProductLink product={Dashboard}>
                  Dashboard
                </NavigationDropdownMenuProductLink>
              </InternalLink>

              <Box p={6} pt={0}>
                <Divider my={6} />
                <Stack>
                  <a href={EXTERNAL_LINKS.SIGNUP_UP.href}>
                    <Button size="lg" isBlock>
                      Get started
                    </Button>
                  </a>
                  <a href={EXTERNAL_LINKS.LOGIN.href}>
                    <Button size="lg" isBlock color="default">
                      Login
                    </Button>
                  </a>
                </Stack>
              </Box>
            </Box>
          </MotionBox>
        )}
      </AnimatePresence>
    </Portal>
  );
};
