import React from "react";
import { AspectRatio, Box } from "@chakra-ui/react";
import ReactPlayer from "react-player";

import styled from "styled-components";

const Styles = styled.div`
  video {
    object-fit: cover;
  }
`;

export const ContentfulVideo = ({ node }: { node: any }) => {
  const entry = node.data.target;
  const videoURL = entry.fields.file.url;

  return (
    <Box mt={6}>
      <Styles>
        <AspectRatio ratio={16 / 9} border="1px solid" borderColor="gray.150" rounded="md" overflow="hidden" bg="white">
          <ReactPlayer
            url={videoURL}
            width="100%"
            height="100%"
            controls={true}
            style={{ outline: "none" }}
          />
        </AspectRatio>
      </Styles>
    </Box>
  );
};
