import { Box } from "@chakra-ui/layout";

interface IRadio {
  name: string;
  value: string;
  isChecked: boolean;
  onChange(): void;
}

export const Radio = ({ name, value, isChecked, onChange }: IRadio) => {
  return (
    <Box
      as="label"
      display="flex"
      alignItems="center"
      cursor="pointer"
      onClick={onChange}
      role="group"
      tabIndex={0}
      _focus={{
        outline: "none",
      }}
    >
      <Box
        w={5}
        h={5}
        mr={2}
        rounded="full"
        p={1}
        border="1px solid"
        borderColor={isChecked ? "primary.600" : "gray.300"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        _hover={{
          borderColor: "gray.500",
        }}
        _focus={{
          borderColor: "gray.500",
          outline: "none",
        }}
      >
        <Box
          as="input"
          id={name}
          type="radio"
          name={name}
          value={value}
          whiteSpace="nowrap"
          opacity={0}
          height="1px"
          width="1px"
          marginLeft="-1px"
          overflow="hidden"
          border="none"
          padding={0}
          position="absolute"
        />
        <Box
          width="100%"
          height="100%"
          bg={isChecked ? "primary.500" : "gray.300"}
          opacity={isChecked ? 1 : 0}
          rounded="full"
          _groupHover={{
            opacity: 1,
          }}
          _groupFocus={{
            opacity: 1,
          }}
        />
      </Box>
      {value}
    </Box>
  );
};
