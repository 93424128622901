import { Box } from "@chakra-ui/layout";
import styled from "styled-components";
import { theme } from "theme";

const Styles = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
    margin-bottom: 0.5em;
  }
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child) {
    margin-top: 2em;
  }

  strong, b {
    font-weight: 600;
  }

  h1 {
    font-size: 2em;
  }
  // h2 {
  //   font-size: 1.35em;
  // }
  // h3 {
  //   font-size: 1.35em;
  // }
  h4 {
    font-size: 1.15em;
  }
  h5 {
    font-size: 1em;
  }
  h6 {
    font-size: 0.75em;
  }
  strong {
    font-weight: 500;
  }
  hr {
    display: block;
    height: 0;
    border: 0;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #eee;
    margin: 1em 0;
    padding: 0;
  }
  pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  p,
  li {
    line-height: 1.75;
  }
  hr {
    opacity: 0.2;
    border-bottom: 0;
  }
  ul li:not(:last-child),
  ol li:not(:last-child) {
    margin-bottom: 0.15em;
  }
  a {
    text-decoration: underline;
    color: ${theme.colors.primary[600]};
  }
  p,
  ul,
  ol,
  .image {
    margin-bottom: 1em;
  }

  li {
    margin-left: 1.25em;
  }
  p:last-child {
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
  }

  ol ol {
    padding-left: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    list-style-type: lower-latin;
  }
`;

export const Typography = ({
  children,
  baseSize = "md",
}: {
  children?: any;
  baseSize?: any;
}) => {
  return (
    <Box fontSize={baseSize}>
      <Styles>{children}</Styles>
    </Box>
  );
};
