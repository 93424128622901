import React, { Component, useContext } from "react";
import {
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

export const SmoothScrollLink = ({
  children,
  to,
  className,
  offset = 0,
}: {
  to: string;
  offset?: number;
  children: any;
  className?: string;
}) => {
  const { scrollTo } = useContext(ScrollContext);

  return (
    <a
      onClick={(e) =>
        scrollTo(to, {
          offset: -80,
        })
      }
      className={className}
    >
      {children}
    </a>
  );
};

const ScrollContext = React.createContext<any>({});

export class SmoothScrollProvider extends Component {
  componentDidMount = () => {
    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();
  };
  componentWillUnmount = () => {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  };
  scrollToTop = () => {
    scroll.scrollToTop();
  };
  scrollToBottom = () => {
    scroll.scrollToBottom();
  };
  scrollTo(section, options) {
    scroller.scrollTo(section, {
      duration: 800,
      smooth: true,
      ...options
    });
  }
  scrollMore = () => {
    scroll.scrollMore(100);
  };
  handleSetActive = (to) => {
    console.log(to);
  };
  render() {
    const { children } = this.props;
    return (
      <ScrollContext.Provider
        value={{
          scrollTo: this.scrollTo,
        }}
      >
        {children}
      </ScrollContext.Provider>
    );
  }
}
