import { Container } from "components";
import { Box, HStack, Divider, Image, Flex } from "@chakra-ui/react";
import { FooterLinksColumn } from "./FooterLinksColumn";
import { FooterLink, FooterItem } from "./FooterLink";
import { FooterCopyright } from "./FooterCopyright";
import { SimpleGrid } from "@chakra-ui/react";
import { route } from "config/routes";
import { CS_EMAIL, CS_PHONE } from "config/site";
import { useAppContext } from "pages/_app";
import { InternalLink } from "components/atoms/InternalLink";

export const Footer = () => {
  const { footerBackgroundColor } = useAppContext();

  return (
    <Box>
      <Container>
        <Divider mb={{ base: 12, lg: 24 }} borderColor="gray.150" />
        <Box display={{ lg: "flex" }} flexWrap="wrap" width="100%">
          <Box w={12} mb={8} mr={24}>
            <InternalLink href={route("HOMEPAGE_INDEX")}>
              <Image src="/images/logo/glyph.png" />
            </InternalLink>
          </Box>
          <SimpleGrid columns={{ base: 2, md: 4 }} flex={1}>
            <FooterLinksColumn title="Products">
              <FooterLink href={route("INSTANT_VALUATION_TOOL_INDEX")}>
                Instant Valuation Tool
              </FooterLink>
              <FooterLink href={route("AUTOCALLER_INDEX")}>
                Autocaller
              </FooterLink>
              <FooterLink href={route("LEAD_RESPONDER_INDEX")}>
                Lead Responder
              </FooterLink>
              <FooterLink href={route("DASHBOARD_INDEX")}>Dashboard</FooterLink>
              <FooterLink href={route("FACEBOOK_ADVERTISING_INDEX")}>
                Facebook Advertising
              </FooterLink>
            </FooterLinksColumn>

            <FooterLinksColumn title="Resources">
              <FooterLink href={route("CUSTOMERS_INDEX")}>Customers</FooterLink>
              <FooterLink
                href={route("GUIDES_INDEX", "using-the-leadpro-dashboard")}
              >
                Guides
              </FooterLink>
              <FooterLink href={route("LEGAL_INDEX")}>Legal</FooterLink>
              <FooterLink href={route("COMPLAINTS_INDEX")}>
                Complaints
              </FooterLink>
              <FooterLink href={route("RELEASE_NOTES")}>Change Log</FooterLink>
            </FooterLinksColumn>
          </SimpleGrid>
        </Box>
        <Divider my={{ base: 6, lg: 12 }} borderColor="gray.150" />
        <Flex justifyContent={"center"}>
          <FooterCopyright />
        </Flex>
      </Container>
    </Box>
  );
};
