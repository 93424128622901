import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  config: {
    disableTransitionOnChange: false,
  },
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  fontSizes: {
    "5xl": "2.5rem",
    "6xl": "3rem",
  },
  colors: {
    primary: {
      50: "#F6FCFB",
      100: "#E5F5F3",
      200: "#CCECE8",
      300: "#99D8D2",
      400: "#4DBBAF",
      500: "#00A998",
      600: "#01887A",
      700: "#006E62",
      800: "#00473F",
      900: "#003732",
    },
    blue: {
      50: "#F2F7FD",
      100: "#E6EEFA",
      200: "#BFD5F3",
      300: "#99BBEC",
      400: "#4D89DD",
      500: "#0056CF",
      600: "#004DBA",
      700: "#00419B",
      800: "#00347C",
      900: "#002A65",
    },
    purple: {
      50: "#F6F2FC",
      100: "#EDE6FA",
      200: "#D3C0F1",
      300: "#B89AE9",
      400: "#824FD9",
      500: "#4D03C8",
      600: "#4503B4",
      700: "#3A0296",
      800: "#2E0278",
      900: "#260162",
    },
    secondary: {
      50: "#EFF5FE",
      100: "#D8E5FB",
      "200": "#A9C7F7",
      "300": "#7AA8F3",
      "400": "#4C8AEE",
      "500": "#1D6BEA",
      "600": "#1255C2",
      "700": "#0E4193",
      "800": "#092C65",
      "900": "#051836",
    },
    gray: {
      50: "#FBFBFB",
      100: "#F5F5F5",
      150: "#efefef",
      200: "#DEDEDE",
      300: "#C5C5C5",
      400: "#A5A4A4",
      500: "#8F8D8D",
      600: "#737171",
      700: "#504E4E",
      800: "#242121",
      900: "#131212",
    },
  },
});
