import "styles/nprogress.css";
import React, { useState, useEffect } from "react";
import App from "next/app";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import { NextSeo } from "next-seo";
import { LayoutDefault } from "components/layouts/LayoutDefault";
import { AnimatePresence } from "framer-motion";
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import { theme } from "theme";
import { useContext } from "react";
import { NextPage } from "next";
import { DEFAULT_SEO } from "config/seo";
import { SmoothScrollProvider } from "components/atoms/SmoothScroll";
import { fetchById } from "services/contentful";
import { IProduct, ProductColors } from "interfaces/product.interface";
import { Box } from "@chakra-ui/react";
import { route, ROUTES } from "config/routes";
import {
  AUTOCALLER_CONTENTFUL_ENTRY_ID,
  DASHBOARD_ADVERTISING_CONTENTFUL_ENTRY_ID,
  FACEBOOK_ADVERTISING_CONTENTFUL_ENTRY_ID,
  INSTANT_VALUATION_TOOL_CONTENTFUL_ENTRY_ID,
  LEAD_RESPONDER_CONTENTFUL_ENTRY_ID,
  MORTGAGES_CONTENTFUL_ENTRY_ID,
  REAPIT_INTEGRATION_CONTENTFUL_ENTRY_ID,
} from "constants/contentful";
import { GOOGLE_TAG_MANAGER_ID } from "config/site";
import TagManager from "react-gtm-module";
import { ActionPromptProvider } from "components/atoms/actionPrompt/ActionPrompt";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export interface IAppContext {
  isNavigationInversed: boolean;
  setNavigationInversed(state: boolean): void;
  setFooterBackgroundColor(color: "white" | "gray.100"): void;
  footerBackgroundColor: "white" | "gray.100";
  colorScheme: ProductColors;
  products: {
    IVT: IProduct;
    LeadResponder: IProduct;
    Autocaller: IProduct;
    Mortgages: IProduct;
    FacebookAdvertising: IProduct;
    Dashboard: IProduct;
    ReapitIntegration: IProduct;
  };
}

const AppContextProvider = React.createContext<IAppContext>({
  isNavigationInversed: false,
  setNavigationInversed: () => {},
  setFooterBackgroundColor: () => {},
  footerBackgroundColor: "white",
  colorScheme: "primary",
  products: {
    IVT: {} as IProduct,
    LeadResponder: {} as IProduct,
    Mortgages: {} as IProduct,
    Autocaller: {} as IProduct,
    FacebookAdvertising: {} as IProduct,
    Dashboard: {} as IProduct,
    ReapitIntegration: {} as IProduct,
  },
});

export const useAppContext = () => useContext(AppContextProvider);

type AppLayoutProps = {
  Component: PageWithLayoutType;
  pageProps: any;
  products: {
    IVT: IProduct;
    Autocaller: IProduct;
    LeadResponder: IProduct;
    Mortgages: IProduct;
    FacebookAdvertising: IProduct;
    Dashboard: IProduct;
    ReapitIntegration: IProduct;
  };
};

type PageWithLayoutType = NextPage & {
  getLayout: Function;
  colorScheme: any;
  footerBackgroundColor: "white" | "gray.100";
  isNavigationInversed?: boolean;
};

function LeadPro({ Component, pageProps, products }: AppLayoutProps) {
  const router = useRouter();

  const [isNavigationInversed, setNavigationInversed] = useState(false);

  const [colorScheme, setColorScheme] = useState<ProductColors>("primary");

  const [footerBackgroundColor, setFooterBackgroundColor] = useState<
    "white" | "gray.100"
  >("white");

  const { pathname } = router;

  const getLayout =
    Component.getLayout || ((page) => <LayoutDefault children={page} />);

  useEffect(() => {
    if (Component.isNavigationInversed) {
      setNavigationInversed(Component.isNavigationInversed);
    } else {
      setNavigationInversed(false);
    }

    setColorScheme("primary");

    if (pathname === ROUTES.INSTANT_VALUATION_TOOL_INDEX.path) {
      setColorScheme(products.IVT.color);
    }

    if (pathname === ROUTES.AUTOCALLER_INDEX.path) {
      setColorScheme(products.Autocaller.color);
    }

    if (Component.footerBackgroundColor) {
      setFooterBackgroundColor(Component.footerBackgroundColor);
    } else {
      setFooterBackgroundColor("white");
    }
  }, [pathname]);

  useEffect(() => {
    TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AppContextProvider.Provider
        value={{
          isNavigationInversed,
          setNavigationInversed,
          colorScheme,
          footerBackgroundColor,
          setFooterBackgroundColor,
          products,
        }}
      >
        <NextSeo {...DEFAULT_SEO} />
        <AnimatePresence mode={"wait"}>
          <SmoothScrollProvider>
            <ActionPromptProvider>
              <Box bg="gray.50">
                {getLayout(<Component {...pageProps} key={router?.route} />)}
              </Box>
            </ActionPromptProvider>
          </SmoothScrollProvider>
        </AnimatePresence>
      </AppContextProvider.Provider>
    </ChakraProvider>
  );
}

LeadPro.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  const { data: IVT } = await fetchById({
    id: INSTANT_VALUATION_TOOL_CONTENTFUL_ENTRY_ID,
  });

  const { data: LeadResponder } = await fetchById({
    id: LEAD_RESPONDER_CONTENTFUL_ENTRY_ID,
  });

  const { data: Autocaller } = await fetchById({
    id: AUTOCALLER_CONTENTFUL_ENTRY_ID,
  });

  const { data: FacebookAdvertising } = await fetchById({
    id: FACEBOOK_ADVERTISING_CONTENTFUL_ENTRY_ID,
  });

  const { data: Mortgages } = await fetchById({
    id: MORTGAGES_CONTENTFUL_ENTRY_ID,
  });

  const { data: Dashboard } = await fetchById({
    id: DASHBOARD_ADVERTISING_CONTENTFUL_ENTRY_ID,
  });

  const { data: ReapitIntegration } = await fetchById({
    id: REAPIT_INTEGRATION_CONTENTFUL_ENTRY_ID,
  });

  return {
    ...appProps,
    products: {
      IVT,
      LeadResponder,
      Autocaller,
      Mortgages,
      FacebookAdvertising,
      ReapitIntegration,
      Dashboard,
    },
  };
};

export default LeadPro;
