export const AUTOCALLER_CONTENTFUL_ENTRY_ID = "1AiE4OAfBZttGeF2aiAkkH";

export const LEAD_RESPONDER_CONTENTFUL_ENTRY_ID = "6KiO4d2K53QHQ6xKCl4RaG";

export const INSTANT_VALUATION_TOOL_CONTENTFUL_ENTRY_ID =
  "49htDE6Z6Yf9AUbqI1AVLe";

export const FACEBOOK_ADVERTISING_CONTENTFUL_ENTRY_ID =
  "2tY0KoG5NXBi6YYi12Fqwi";

export const MORTGAGES_CONTENTFUL_ENTRY_ID = "22rDBDrAYI8g4IuDcvAlRL";

export const DASHBOARD_ADVERTISING_CONTENTFUL_ENTRY_ID =
  "ONyERnxm1hHmzi2dYVo3V";

export const REAPIT_INTEGRATION_CONTENTFUL_ENTRY_ID = "6iYK21qAsjuielUK5uTHVs";
