import stringify from "fast-stringify";

import { isObject } from "utils/isObject";
import { isArray } from "utils/isArray";
import { ContentfulEntityStrings } from "types/contentful-entities.types";
import { toCamelCase } from "utils/toCamelCase";

const contentful = require("contentful");

const Contentful = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_KEY,
});

export const fetchTestimonials = async () => {
  return await fetchAll({ entity: "testimonial" });
};

export const fetchWebinarBySlug = async (slug: string) => {
  return await fetchBySlug({ entity: "webinar", slug });
};

export const fetchIntegrations = async () => {
  return await fetchAll({ entity: "integration" });
};

export const fetchFaqs = async () => {
  return await fetchAll({ entity: "faq" });
};

export const fetchGuideProducts = async () => {
  return await fetchAll({ entity: "product" });
};

export const fetchGuides = async () => {
  return await fetchAll({ entity: "article" });
};

export const fetchGuideTileSection = async () => {
  return await fetchAll({ entity: "guideTileSection" });
};

export const fetchProductBySlug = async (slug: string) => {
  return await fetchBySlug({
    entity: "product",
    slug,
  });
};

export const fetchGuideCategoryBySlug = async (slug: string) => {
  return await fetchBySlug({
    entity: "product",
    slug,
  });
};

export const fetchGuideMenu = async (slug: string) => {
  return await fetchBySlug({
    entity: "guideMenu",
    slug,
  });
};

export const fetchInternalGuideMenu = async (slug: string) => {
  return await fetchBySlug({
    entity: "internalGuideMenu",
    slug,
  });
};

export const fetchGuideCategoryBySlugGuides = async (slug: string) => {
  return await fetchAll({
    entity: "article",
    options: {
      "fields.product.sys.contentType.sys.id": "product",
      "fields.product.fields.slug[in]": slug,
    },
  });
};

export const fetchGuideBySlug = async (slug: string) => {
  return await fetchBySlug({
    entity: "article",
    slug,
  });
};

export const fetchInternalGuideBySlug = async (slug: string) => {
  return await fetchBySlug({
    entity: "internalTeamGuideArticle",
    slug,
  });
};

export const searchGuides = async (query) => {
  return await fetchAll({
    entity: "article",
    options: {
      "fields.title[match]": query,
    },
  });
};

export const fetchLegalArticles = async () => {
  return await fetchAll({ entity: "legalArticle" });
};

export const fetchLegalMenu = async (slug: string) => {
  return await fetchBySlug({
    entity: "legalMenu",
    slug,
  });
};

export const fetchLegalArticleBySlug = async (slug: string) => {
  return await fetchBySlug({
    entity: "legalArticle",
    slug,
  });
};

export const fetchReleases = async () => {
  return await fetchAll({
    entity: "release",
  });
};

export const fetchAll = async ({
  entity,
  options,
}: {
  entity: ContentfulEntityStrings;
  options?: any;
}) => {
  const resp = await Contentful.getEntries({
    content_type: entity,
    include: 3,
    ...options,
  });

  const { total, skip, limit } = resp;

  return handleSuccess({
    items: resp.items,
    pagination: {
      limit,
      total,
      skip,
    },
  });
};

export const fetchById = async ({ id, include = 3 }) => {
  const resp = await Contentful.getEntry(id, {
    include,
  });

  return handleSuccess({ items: resp });
};

export const fetchBySlug = async ({ entity, slug, include = 3 }) => {
  const resp = await Contentful.getEntries({
    content_type: entity,
    include,
    "fields.slug[in]": slug,
  });

  return handleSuccess({ items: resp.items[0] });
};

export const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = { id: o.sys.id };

    if (o.fields) {
      Object.keys(o.fields).forEach((k) => {
        if (o.fields[k].content) n[k] = o.fields[k];
        else if (o.fields[k].url) n[k] = o.fields[k];
        else n[toCamelCase(k)] = keysToCamel(o.fields[k]);
      });
    }

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const handleSuccess = (data) => {
  let { items, pagination } = data;
  if (!items || items.length === 0) return { data: null, pagination: null };

  items = JSON.parse(stringify(items));

  if (isArray(items)) {
    const formattedItems = [];

    items.map((obj) => {
      formattedItems.push(keysToCamel(obj));
    });

    return {
      data: formattedItems,
      pagination,
    };
  } else {
    return {
      data: keysToCamel(items),
      pagination,
    };
  }
};
