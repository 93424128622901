import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import React from "react";
import { Box } from "@chakra-ui/react";

const styles = {
  padding: "0",
  backgroundColor: "transparent",
  borderRadius: "0",
};

export const ContentfulCodeBlock = ({ data }: { data: any }) => {
  return (
    <Box my={5} bg="gray.100" rounded="lg" fontSize="xs" p={4}>
      <SyntaxHighlighter
        customStyle={styles}
        language="javascript"
        // style={docco}
        
      >
        {data}
      </SyntaxHighlighter>
    </Box>
  );
};
