import { Box } from "@chakra-ui/react";

export const FormFieldErrorMessage = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box lineHeight="none" fontSize="sm" mt={2} color="red.800">
      {children}
    </Box>
  );
};
