import { Box, Stack } from "@chakra-ui/layout";
import { Radio } from "./Radio";

export const RadioGroup = ({
  id,
  name,
  options,
  value,
  onChange,
  isInvalid,
}: {
  id: string;
  name: string;
  value: string;
  options: string[];
  onChange(): void;
  isInvalid?: boolean;
}) => {
  return (
    <Box id={id}>
      <Stack spacing={4}>
        {options.map((option: string, index: number) => {
          return (
            <Radio
              key={index}
              name={name}
              value={option}
              isChecked={option === value}
              onChange={onChange}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
