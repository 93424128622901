import { Box, Theme } from "@chakra-ui/react";
import { Loader } from "./Loader";

interface IButton {
  children: any;
  onClick?(): void;
  type?: "button" | "submit" | "reset";
  size?: ButtonSizes;
  color?: ButtonColors;
  variant?: ButtonVariants;
  isCapsule?: boolean;
  isBlock?: boolean;
  isLoading?: boolean;
  isWide?: boolean;
  isDisabled?: boolean;
}

interface IButtonSize {
  height: number;
  fontSize: "sm" | "md" | "lg";
  px: number;
}

interface IButtonColorStateAttributes {
  background?: string;
  borderColor?: string;
  color?: string;
}

interface IButtonColorAttributes {
  default: IButtonColorStateAttributes;
  hover: IButtonColorStateAttributes;
}

interface IButtonColor {
  solid: IButtonColorAttributes;
  outline?: IButtonColorAttributes;
  ghost?: IButtonColorAttributes;
}

const ButtonSizes: { [key in ButtonSizes]: IButtonSize } = {
  sm: {
    height: 9,
    fontSize: "sm",
    px: 6,
  },
  md: {
    height: 10,
    px: 5,
    fontSize: "md",
  },
  lg: {
    height: 12,
    px: 6,
    fontSize: "md",
  },
};

export type ButtonColors = keyof Theme["colors"] | "default" | "primary";

type ButtonSizes = "sm" | "md" | "lg";

type ButtonVariants = "solid" | "outline" | "ghost";

const ButtonColors: Partial<Record<ButtonColors, IButtonColor>> = {
  default: {
    solid: {
      default: {
        background: "white",
        borderColor: "gray.200",
        color: "black",
      },
      hover: {
        background: "",
        borderColor: "gray.300",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "gray.700",
      },
      hover: {
        background: "gray.100",
        color: "gray.900",
      },
    },
  },
  primary: {
    solid: {
      default: {
        background: "primary.500",
        borderColor: "primary.600",
        color: "white",
      },
      hover: {
        background: "primary.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "primary.500",
        color: "primary.500",
      },
      hover: {
        background: "primary.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "primary.500",
      },
      hover: {
        background: "primary.500",
        color: "primary.500",
      },
    },
  },
  blue: {
    solid: {
      default: {
        background: "blue.500",
        borderColor: "blue.600",
        color: "white",
      },
      hover: {
        background: "blue.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "primary.500",
        color: "primary.500",
      },
      hover: {
        background: "primary.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "primary.500",
      },
      hover: {
        background: "primary.50",
        color: "white",
      },
    },
  },
  purple: {
    solid: {
      default: {
        background: "purple.500",
        borderColor: "purple.600",
        color: "white",
      },
      hover: {
        background: "purple.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "purple.500",
        color: "purple.500",
      },
      hover: {
        background: "purple.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "primary.500",
      },
      hover: {
        background: "primary.50",
        color: "white",
      },
    },
  },
  pink: {
    solid: {
      default: {
        background: "pink.500",
        borderColor: "pink.600",
        color: "white",
      },
      hover: {
        background: "pink.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "primary.500",
        color: "pink.500",
      },
      hover: {
        background: "pink.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "primary.500",
      },
      hover: {
        background: "primary.50",
        color: "white",
      },
    },
  },
  cyan: {
    solid: {
      default: {
        background: "cyan.500",
        borderColor: "cyan.600",
        color: "white",
      },
      hover: {
        background: "purple.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "cyan.500",
        color: "cyan.500",
      },
      hover: {
        background: "cyan.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "primary.500",
      },
      hover: {
        background: "primary.50",
        color: "white",
      },
    },
  },
  green: {
    solid: {
      default: {
        background: "green.500",
        borderColor: "green.600",
        color: "white",
      },
      hover: {
        background: "green.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "green.500",
        color: "green.500",
      },
      hover: {
        background: "green.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "green.500",
      },
      hover: {
        background: "green.50",
        color: "white",
      },
    },
  },
  red: {
    solid: {
      default: {
        background: "red.500",
        borderColor: "red.600",
        color: "white",
      },
      hover: {
        background: "red.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "red.500",
        color: "red.500",
      },
      hover: {
        background: "red.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "red.500",
      },
      hover: {
        background: "red.50",
        color: "white",
      },
    },
  },
  teal: {
    solid: {
      default: {
        background: "teal.500",
        borderColor: "teal.600",
        color: "white",
      },
      hover: {
        background: "teal.700",
        color: "white",
      },
    },
    outline: {
      default: {
        background: "transparent",
        borderColor: "teal.500",
        color: "teal.500",
      },
      hover: {
        background: "teal.500",
        color: "white",
      },
    },
    ghost: {
      default: {
        background: "transparent",
        borderColor: "transparent",
        color: "team.500",
      },
      hover: {
        background: "teal.50",
        color: "white",
      },
    },
  },
};

export const Button = ({
  children,
  size = "md",
  color = "primary",
  variant = "solid",
  type = "button",
  onClick,
  isCapsule,
  isBlock,
  isLoading,
  isDisabled = false,
  isWide,
}: IButton) => {
  const isLoadingStyles = {
    cursor: "not-allowed",
    backgroundColor: "gray.300",
    borderColor: "gray.300",
  };

  const selectedColor = (ButtonColors[color] &&
    ButtonColors[color][variant]) || { default: {}, hover: {} };

  return (
    <Box
      as="button"
      border="1px solid"
      px={isWide ? ButtonSizes[size].px * 2 : ButtonSizes[size].px}
      cursor={"pointer"}
      pointerEvents={isDisabled ? "none" : "auto"}
      type={type}
      height={ButtonSizes[size].height}
      display="inline-flex"
      alignItems="center"
      rounded={isCapsule ? "full" : "md"}
      transition="all .25s"
      fontSize={ButtonSizes[size].fontSize}
      width={isBlock ? "100%" : "auto"}
      fontWeight={color === "default" ? 500 : 700}
      whiteSpace="nowrap"
      opacity={isDisabled && 0.5}
      justifyContent="center"
      lineHeight="none"
      {...(onClick && { onClick })}
      {...(isLoading && isLoadingStyles)}
      {...selectedColor.default}
      _hover={!isLoading && { ...selectedColor.hover }}
    >
      <Box opacity={isLoading && 0}>{children}</Box>
      {isLoading && (
        <Box position="absolute">
          <Loader isActive={true} />
        </Box>
      )}
    </Box>
  );
};
