import { Box, useDisclosure, Heading } from "@chakra-ui/react";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { isBrowser } from "@chakra-ui/utils";
import { useRouter } from "next/router";

interface INavigationDropdownContext {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
}

const NavigationDropdownContext =
  React.createContext<INavigationDropdownContext>({
    isOpen: false,
    onClose: () => {},
    onOpen: () => {},
  });

const useNavigationDropdown = () => useContext(NavigationDropdownContext);

const slideVerticalAnimation = {
  open: {
    rotateX: 0,
    opacity: 1,

    transition: {
      duration: 0.3,
      mass: 0.8,
      type: "spring",
    },
  },
  close: {
    rotateX: -15,
    opacity: 0,
    transition: {
      duration: 0.3,
      mass: 0.8,
      type: "spring",
    },
  },
};

export const NavigationDropdownToggle = ({ children }) => {
  const { onOpen, onClose } = useNavigationDropdown();
  return <Box onMouseEnter={onOpen}>{children}</Box>;
};

export const NavigationDropdown = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <NavigationDropdownContext.Provider
      value={{
        isOpen,
        onOpen,
        onClose,
      }}
    >
      <Box position="relative" onMouseLeave={onClose}>
        {children}
      </Box>
    </NavigationDropdownContext.Provider>
  );
};

export const NavigationDropdownMenu = ({ children }) => {
  const { isOpen, onClose } = useNavigationDropdown();

  const router = useRouter();

  if (isBrowser) router.events.on("routeChangeStart", onClose);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="close"
          animate={isOpen ? "open" : "close"}
          exit="close"
          variants={slideVerticalAnimation}
          style={{
            transformOrigin: "50% -50px;",
            translateX: "-50%",
            left: "50%",
            // paddingTop: 10,
            minWidth: 400,
            position: "absolute",
          }}
        >
          <Box
            bg="white"
            boxShadow="0 0 1px 0 rgb(0 0 0 / 16%), 0 24px 24px 0 rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 4px 4px 0 rgb(0 0 0 / 5%), 0 8px 8px 0 rgb(0 0 0 / 5%), 0 16px 16px 0 rgb(0 0 0 / 5%);"
            rounded="4px"
            mt={5}
            py={4}
          >
            <Box>{children}</Box>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const NavigationDropdownMenuLink = ({ children }) => {
  return <Box>{children}</Box>;
};
