import { Box } from "@chakra-ui/react";
import { useAppContext } from "pages/_app";
import React from "react";

export const NavigationLink = ({
  children,
  isActive,
}: {
  children: React.ReactNode;
  isActive: boolean;
}) => {
  const { colorScheme } = useAppContext();
  return (
    <Box
      cursor="pointer"
      px={4}
      fontWeight={500}
      transition="color .25s"
      color={isActive && `${colorScheme}.600`}
      _hover={{
        color: `${colorScheme}.600`,
      }}
    >
      {children}
    </Box>
  );
};
