export enum EmbedTypes {
  video = "video",
  image = "image",
}

export enum ContenfulEntityType {
  INTERNAL_GUIDE = "internalTeamGuideArticle",
}

export enum ContentfulMenuSlug {
  INTERNAL_GUIDE_MENU = "internal-guide-menu",
  GUIDE_MENU = "default",
  LEGAL_MENU = "default",
}
