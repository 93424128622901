import { Box, useDisclosure } from "@chakra-ui/react";
import { NavigationContainer } from "./NavigationContainer";
import { NavigationLink } from "./NavigationLink";
import {
  NavigationDropdown,
  NavigationDropdownMenu,
  NavigationDropdownToggle,
} from "./NavigationDropdown";
import { NavigationItem } from "./NavigationItem";
import { Button } from "components";
import { NavigationHamburger } from "./NavigationHamburger";
import { NavigationMobileDrawer } from "./NavigationMobileDrawer";
import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { InternalLink } from "components/atoms/InternalLink";
import { EXTERNAL_LINKS, route } from "config/routes";
import { NavigationLogo } from "./NavigationLogo";
import { useContext } from "react";
import { useAppContext } from "pages/_app";
import { NavigationDropdownMenuProductLink } from "./NavigationDropdownMenuProductLink";
import { NavigationLinks } from "./NavigationLinks";
import { NavigationMain } from "./NavigationMain";
import { useRouter } from "next/router";
import { AnimatePresence, motion } from "framer-motion";

export const mobileBreakpoint = "lg";

const NavigationContext = React.createContext<{
  isOpen: boolean;
  isScrolled: boolean;
  onOpen(): void;
  onClose(): void;
  scrollOffset: number;
  setScrollOffset(value: number): void;
}>({
  isOpen: false,
  isScrolled: false,
  onOpen: () => {},
  onClose: () => {},
  scrollOffset: 0,
  setScrollOffset: () => {},
});

export const useNavigationContext = () => useContext(NavigationContext);

export const Navigation = () => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const [scrollOffset, setScrollOffset] = useState<number>(0);

  const { colorScheme } = useAppContext();

  const {
    products: {
      IVT,
      Autocaller,
      LeadResponder,
      FacebookAdvertising,
      Dashboard,
      Mortgages,
    },
  } = useAppContext();

  const [referenceElement, setReferenceElement] = useState(null);

  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top",
  });

  const { pathname } = useRouter();

  const [isScrolled, setScrolled] = React.useState<boolean>(false);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset >= scrollOffset) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const variants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="hidden"
        transition={{ duration: 0.5, delay: 0.05 }}
        animate="visible"
      >
        <NavigationContext.Provider
          value={{
            isOpen,
            onOpen,
            onClose,
            isScrolled,
            scrollOffset,
            setScrollOffset,
          }}
        >
          <Box position="fixed" width="100%" zIndex={999}>
            <NavigationMain>
              <NavigationContainer>
                <NavigationLogo />
                <NavigationLinks>
                  <NavigationItem>
                    <NavigationDropdown>
                      <NavigationDropdownToggle>
                        <NavigationLink isActive={false}>
                          Products
                        </NavigationLink>
                      </NavigationDropdownToggle>
                      <NavigationDropdownMenu>
                        <InternalLink
                          href={route("INSTANT_VALUATION_TOOL_INDEX")}
                        >
                          <NavigationDropdownMenuProductLink product={IVT}>
                            Instant Valuation Tool
                          </NavigationDropdownMenuProductLink>
                        </InternalLink>
                        <InternalLink href={route("LEAD_RESPONDER_INDEX")}>
                          <NavigationDropdownMenuProductLink
                            product={LeadResponder}
                          >
                            LeadResponder
                          </NavigationDropdownMenuProductLink>
                        </InternalLink>
                        <InternalLink href={route("AUTOCALLER_INDEX")}>
                          <NavigationDropdownMenuProductLink
                            product={Autocaller}
                          >
                            Autocaller
                          </NavigationDropdownMenuProductLink>
                        </InternalLink>
                        <InternalLink
                          href={route("FACEBOOK_ADVERTISING_INDEX")}
                        >
                          <NavigationDropdownMenuProductLink
                            product={FacebookAdvertising}
                          >
                            Facebook Advertising
                          </NavigationDropdownMenuProductLink>
                        </InternalLink>
                        <InternalLink href={route("DASHBOARD_INDEX")}>
                          <NavigationDropdownMenuProductLink
                            product={Dashboard}
                          >
                            Dashboard
                          </NavigationDropdownMenuProductLink>
                        </InternalLink>
                        <InternalLink href={route("MORTAGES_INDEX")}>
                          <NavigationDropdownMenuProductLink
                            product={Mortgages}
                          >
                            Mortgages
                          </NavigationDropdownMenuProductLink>
                        </InternalLink>
                      </NavigationDropdownMenu>
                    </NavigationDropdown>
                  </NavigationItem>
                  <NavigationItem>
                    <InternalLink href={route("PRICING_INDEX")}>
                      <NavigationLink
                        isActive={pathname === route("PRICING_INDEX")}
                      >
                        Pricing
                      </NavigationLink>
                    </InternalLink>
                  </NavigationItem>
                  <NavigationItem>
                    <InternalLink href={route("INTEGRATIONS_INDEX")}>
                      <NavigationLink
                        isActive={pathname === route("INTEGRATIONS_INDEX")}
                      >
                        Integrations
                      </NavigationLink>
                    </InternalLink>
                  </NavigationItem>
                  <NavigationItem>
                    <InternalLink href={route("GUIDES_INDEX")}>
                      <NavigationLink
                        isActive={pathname === route("GUIDES_INDEX")}
                      >
                        Guides
                      </NavigationLink>
                    </InternalLink>
                  </NavigationItem>
                  <NavigationItem>
                    <InternalLink href={route("CUSTOMERS_INDEX")}>
                      <NavigationLink
                        isActive={pathname === route("CUSTOMERS_INDEX")}
                      >
                        Customers
                      </NavigationLink>
                    </InternalLink>
                  </NavigationItem>
                  <NavigationItem>
                    <InternalLink href={route("CONTACT_INDEX")}>
                      <NavigationLink
                        isActive={pathname === route("CONTACT_INDEX")}
                      >
                        Contact
                      </NavigationLink>
                    </InternalLink>
                  </NavigationItem>
                </NavigationLinks>
                <Box
                  ml="auto"
                  display={{ base: "none", [mobileBreakpoint]: "flex" }}
                  alignItems="center"
                >
                  <NavigationItem>
                    <Box as="a" href={EXTERNAL_LINKS.LOGIN.href} mr={3}>
                      <Button size="sm" color="default" isBlock>
                        Login
                      </Button>
                    </Box>
                  </NavigationItem>
                  <NavigationItem>
                    <Box as="a" href={EXTERNAL_LINKS.SIGNUP_UP.href}>
                      <Button size="sm" color={colorScheme}>
                        Get started
                      </Button>
                    </Box>
                  </NavigationItem>
                </Box>
                <Box
                  ml="auto"
                  position="relative"
                  display={{ [mobileBreakpoint]: "none" }}
                >
                  <Box
                    ref={setReferenceElement}
                    w={10}
                    h={10}
                    onClick={onToggle}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <NavigationHamburger />
                  </Box>
                  <NavigationMobileDrawer />
                </Box>
              </NavigationContainer>
            </NavigationMain>
          </Box>
        </NavigationContext.Provider>
      </motion.div>
    </AnimatePresence>
  );
};
