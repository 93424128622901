import { Box } from "@chakra-ui/react";
import React from "react";
import { IProduct } from "interfaces/product.interface";
import { HiArrowRight } from "react-icons/hi";

export const NavigationDropdownMenuProductLink = ({
  children,
  product,
}: {
  product: IProduct;
  children: React.ReactNode;
}) => {
  const { color } = product;
  return (
    <Box display="flex" cursor="pointer" alignItems="center" role="group" px={6} py={4} _hover={{
      bg: "gray.100"
    }}>
      <Box flex={1}>
        <Box display="flex">
          <Box
            fontSize="md"
            fontWeight="bold"
            whiteSpace="nowrap"
            lineHeight="none"
            mb={1.5}
            color={`${color}.600`}
            // _groupHover={{
            //   color: "gray.800",
            // }}
          >
            {children}
          </Box>
        </Box>
        <Box
          lineHeight="1.5"
          color="gray.600"
          fontSize="sm"
          _groupHover={{
            color: "gray.800",
          }}
        >
          {product.summary}
        </Box>
      </Box>
    </Box>
  );
};
